<template>
  <div>
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("administrators.addN") }}</h1>
        <h5 class="text-primary">
          {{ $t("code.admin") }}
        </h5>
      </b-col>
      <b-col class="text-right pr-3 pt-2">
        <b-link
          :to="{
            name: 'administrators-list'
          }"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="danger"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </b-link>
      </b-col>
    </b-row>

    <!-- BODY -->
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-card>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col md="4">
              <!-- Username -->
              <b-form-group :label="$t('administrators.user') + ' *'">
                <validation-provider
                  v-slot="validationContext"
                  name="username"
                  rules="required|alpha-dash"
                >
                  <b-form-input
                    id="username"
                    v-model="userData.username"
                    autocomplete="new-password"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :placeholder="$t('userName')"
                  />
                  <small class="text-danger">{{ validationInput(validationContext) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <!-- First Name -->

              <validation-provider
                v-slot="validationContext"
                name="first-name"
                rules=""
              >
                <b-form-group :label="$t('administrators.name')">
                  <b-form-input
                    id="first-name"
                    v-model="userData.firstName"
                    autocomplete="new-password"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('firstName')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <!-- Last Name -->
              <validation-provider
                v-slot="validationContext"
                name="last-name"
                rules=""
              >
                <b-form-group :label="$t('administrators.lname')">
                  <b-form-input
                    id="last-name"
                    v-model="userData.lastName"
                    autocomplete="new-password"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('lastName')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <!-- Email -->
              <validation-provider
                v-slot="validationContext"
                name="Email"
                rules="email|required"
              >
                <b-form-group :label="$t('labelEmail') + ' *'">
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    autocomplete="new-password"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('labelEmail')"
                  />
                  <b-form-invalid-feedback>
                    {{
                      checkMail(validationContext.failedRules) }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <!-- Password -->
              <validation-provider
                v-slot="validationContext"
                name="password"
                vid="password"
                rules="required|min:8|password"
              >
                <b-form-group :label="$t('administrators.pass') + ' *'">
                  <b-input-group>
                    <b-form-input
                      id="password"
                      v-model="userData.password"
                      autocomplete="new-password"
                      :type="passwordFieldType"
                      :placeholder="$t('administrators.pass')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    checkPassword(validationContext.failedRules)
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <!-- Password -->

              <validation-provider
                v-slot="validationContext"
                name="c-password"
                rules="required|confirmed:password"
              >
                <b-form-group :label="$t('administrators.repass') + ' *'">
                  <b-input-group>
                    <b-form-input
                      id="c-password"
                      v-model="userData.cpassword"
                      autocomplete="new-password"
                      :type="passwordFieldType"
                      :placeholder="$t('administrators.repass')"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">
                    {{
                      checkConfirmPassword(validationContext.failedRules)
                    }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-card-title class="mt-1">
            {{ $t("administrators.Permissions") }}
          </b-card-title>
          <b-row>
            <b-col md="4">
              <b-form-select
                v-model="selectedGroups"
                :options="groups"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-left">
              <!-- Form Actions -->
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  type="submit"
                >
                  {{ $t("administrators.addbu") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>
  </div>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BCol,
  BFormSelect,
  BCardTitle,
  BCard,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, email, min, confirmed,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from '@axios'
import { messageError } from '@/store/functions'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'
import i18n from '@/libs/i18n'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BCardTitle,
    BRow,
    BCol,
    BCard,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const blankUserData = {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      password: '',
      cpassword: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const toast = useToast()

    const onSubmit = () => {
      const data = userData.value

      const json = getUserData()
      const client = json.profile.client.id
      axios
        .post('', {
          variables: {
            username: data.username,
            password: data.password,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            client,
          },
          query: `
            mutation($username: String!,$password: String!,$firstName: String,
            $lastName: String,$email: String,$client: ID!) {
              createProfile(input: {
                username: $username,
                password: $password,
                firstName: $firstName,
                lastName: $lastName,
                email: $email,
                isStaff: true,
                client: $client
                }) {
                profile {
                  id
                }
              }
            }
            `,
        })
        .then(response => {
          messageError(response, this)

          if (response.data.data.createProfile !== null) {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('success'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            const permisos = sessionStorage.getItem('Permissions')
            axios
              .post('', {
                query: `
              mutation {
                updateCustomuser(id: "${response.data.data.createProfile.profile.id}" input: {groupsAdd: "${permisos}"}) {
                  customUser {
                    id
                    username
                    groups {
                      edges {
                        node {
                          id
                        }
                      }
                    }
                  }
                }
              }
            `,
              })
              .then(r => {
                messageError(r, this)

                window.history.back()
              })
              .catch(error => {
                console.log(error)
                window.history.back()
              })
          } else {
            toast(
              {
                component: ToastificationContent,
                props: {
                  icon: 'danger',
                  title: 'ERROR',
                  text: i18n.t('userExist'),
                  variant: 'danger',
                },
              },
              {
                timeout: 4000,
              },
            )
          }
        })
        .catch(error => {
          console.log(error)

          toast(
            {
              component: ToastificationContent,
              props: {
                icon: 'danger',
                title: 'ERROR',
                text: i18n.t('error'),
                variant: 'danger',
              },
            },
            {
              timeout: 4000,
            },
          )
        })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData,
    )

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

  data() {
    return {
      required,
      email,
      min,
      confirmed,
      selectedGroups: null,
      groups: [],
      forbid: false,
      isShop: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  watch: {
    selectedGroups() {
      sessionStorage.setItem('Permissions', this.selectedGroups)
    },
  },
  mounted() {
    const user = getUserData()
    try {
      if (user.groups.edges[0].node.name !== constants.administrador) {
        this.forbid = true
      }
      this.isShop = user.profile.client.isShop
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    this.allGroups()
  },

  methods: {
    validationInput(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      if (keyContent.includes('alpha-dash')) {
        return this.$t('userNameNotValid')
      }
      return ''
    },
    checkMail(rule) {
      if (rule.email) {
        return this.$t('emailValidField')
      }
      if (rule.required) {
        return this.$t('emailField')
      }
      return ''
    },

    checkPassword(rule) {
      if (rule.required) {
        return this.$t('password')
      }
      if (rule.min) {
        return this.$t('longPassword')
      }
      if (rule.password) {
        return this.$t('contentPassword')
      }
      return ''
    },

    checkConfirmPassword(rule) {
      if (rule.required) {
        return this.$t('rePassword')
      }
      if (rule.confirmed) {
        return this.$t('confirmPassword')
      }
      return ''
    },

    allGroups() {
      axios
        .post('', {
          query: `
      query
        {
          allGroups {
            edges {
              node {
                id
                name
              }
            }
          }
        }
          `,
        })
        .then(response => {
          messageError(response, this)

          response.data.data.allGroups.edges.forEach(element => {
            switch (element.node.name) {
              case constants.gestorNotifications:
              case constants.publicadorCont:
              case constants.dis:
              case constants.editorCont:
              case constants.gestorStatis:
              case constants.publi:
              case constants.sponsor:
                this.groups.push({
                  value: element.node.id,
                  text: this.$t(element.node.name),
                })
                break
              case constants.gestorUser:
                this.groups.push({
                  value: element.node.id,
                  text: this.$t(element.node.name),
                })
                if (this.forbid) {
                  this.selectedGroups = element.node.id
                  sessionStorage.setItem('Permissions', this.selectedGroups)
                }
                break
              case constants.vendor:
                if (this.isShop) {
                  this.groups.push({
                    value: element.node.id,
                    text: this.$t(element.node.name),
                  })
                }
                break
              case constants.administrador:
                if (!this.forbid) {
                  this.selectedGroups = element.node.id
                  sessionStorage.setItem('Permissions', this.selectedGroups)
                  this.groups.push({
                    value: element.node.id,
                    text: this.$t(element.node.name),
                  })
                }
                break

              default:
                break
            }
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
